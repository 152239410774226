<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center">
              <p><font size="+2">Software Library for Appearance Matching</font></p>
              </div></td>
          </tr>
          <tr>
            <td><div align="center"><a href="https://cave.cs.columbia.edu/old/software/softlib/slam-demo.mpg"><img src="https://cave.cs.columbia.edu/old/software/softlib/gif/slam.jpg" width="600" height="550" border="0"></a></div></td>
          </tr>
          <tr>
            <td><p><span class="style1">Information</span></p>
              <p>The SLAM software package (<a href="https://cave.cs.columbia.edu/old/software/softlib/slam-demo.mpg">see video</a>) was developed at the CAVE lab at Columbia University in the City of New York. To download the software package click at [<a href="http://cave.cs.columbia.edu/old/databases/SLAM_coil-20_coil-100/SLAM/slam.zip">SLAM</a>]. Be sure to look at the file "GettingStarted.txt". Please feel free to direct any questions to <a href="mailto:slam@lists.cs.columbia.edu">slam@lists.cs.columbia.edu</a>. Let us know how your experiments go.
                <br>
                <br>
</p>
      
<blockquote>
<div class="publication" data-v-d81fda88=""><b data-v-d81fda88="">"Appearance-Matching with Partial Data,"<br data-v-d81fda88=""></b><span data-v-d81fda88="">E. Hadjidemetriou and S.K. Nayar,<br data-v-d81fda88=""></span><span data-v-d81fda88="">DARPA Image Understanding Workshop (IUW),<br data-v-d81fda88=""></span><span data-v-d81fda88="">pp. 1071-1078, Nov. 1998<br data-v-d81fda88=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Hadjidemetriou_IUW98.pdf" target="new" data-v-d81fda88="">PDF</a>] [<a href="reference?bid=98" class="url" data-v-d81fda88="">bib</a>] [<a href="copyright" class="url" data-v-d81fda88="">©</a>] </div>
<br><br>
<div class="publication" data-v-d81fda88=""><b data-v-d81fda88="">"Parametric Feature Detection,"<br data-v-d81fda88=""></b><span data-v-d81fda88="">S. Baker, S.K. Nayar and H. Murase,<br data-v-d81fda88=""></span><span data-v-d81fda88="">International Journal on Computer Vision,<br data-v-d81fda88=""></span><span data-v-d81fda88="">Vol. 27, No. 1, pp. 27-50, Mar. 1998<br data-v-d81fda88=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Baker_PAMI98.pdf" target="new" data-v-d81fda88="">PDF</a>] [<a href="reference?bid=107" class="url" data-v-d81fda88="">bib</a>] [<a href="copyright" class="url" data-v-d81fda88="">©</a>] </div>
<br><br>
<div class="publication" data-v-d81fda88=""><b data-v-d81fda88="">"SLAM: A Software Library for Appearance Matching,"<br data-v-d81fda88=""></b><span data-v-d81fda88="">S.A. Nene, S.K. Nayar and H. Murase,<br data-v-d81fda88=""></span><span data-v-d81fda88="">DARPA Image Understanding Workshop (IUW),<br data-v-d81fda88=""></span><span data-v-d81fda88="">Vol. 1, pp. 733-737, Nov. 1994<br data-v-d81fda88=""></span> [<a class="url" href="https://www1.cs.columbia.edu/CAVE/publications/pdfs/Nene_IUW94.pdf" target="new" data-v-d81fda88="">PDF</a>] [<a href="reference?bid=29" class="url" data-v-d81fda88="">bib</a>] [<a href="copyright" class="url" data-v-d81fda88="">©</a>] </div>
</blockquote>

              <p><br>
              </p><hr size="5">
              <address>
              <a href="mailto:webcave@lists.cs.columbia.edu"><br>
              WebMaster</a>
              </address>              </td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>